import React from 'react';
import { Button, Drawer, Form, notification } from 'antd';
import { axius, datatables, helpers, images, types } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import { _setUtils } from 'Store/slices/utils.slice';
import { useDispatch } from 'react-redux';

interface Props {
    open: boolean;
    type: 'virtual' | 'crypto';
    onClose: () => void;
    onSuccess?: (e: types.SettlementAccount) => void;
}
const FormVirtualAccount = ({ open, type, onClose, onSuccess }: Props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { _auth: { business }, _utils: { env, configuration } } = useTypedSelector((state) => state);

    const [v, setValues] = React.useState({ currency: '', code: '' });
    const [isLoading, setSubmitting] = React.useState(false);

    const cc = (type === 'crypto' ? types.CryptoCurrencies : types.VirtualCurrencies);
    const currencies = cc
        .filter(currency => helpers.businessHasAccess(business, currency))
        .filter(currency => !(business.virtual_accounts || []).filter(va => va.stage === env).map(va => va.currency).includes(currency));
    const accounts = Object.keys(configuration.virtual_banks[v.currency] || {});


    React.useEffect(() => {
        if (open) {
            const initialValues = {
                currency: '',
            };
            form.setFieldsValue(initialValues);
        }
        // eslint-disable-next-line
    }, [open]);

    const submit = async () => {
        if (v.currency) {
            setSubmitting(true);
            axius.post(`business/${business.id}/virtual-accounts`, {
                ...v,
                stage: env,
            }).then(res => {
                if ([200, 201].includes(res.status)) {
                    notification.success({
                        message: `${helpers.ucFirst(type)} account successfully added!`,
                        description: `Start receiving funds today.`,
                    });
                    dispatch(_setUtils({ key: 'businessCache', value: res.data.id }));
                    onSuccess && onSuccess(res.data);
                    close();
                } else {
                    notification.error({ ...res, });
                }
                setSubmitting(false);
            });
        } else {
            notification.error({ message: `Please choose a currency` });
        }
    }

    const close = () => {
        onClose();
        setValues({ currency: '', code: '' });
        form.resetFields();
    }

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <Form form={form} layout="vertical" onFinish={submit}>
                    <div className="flex justify-between items-center mb-8">
                        <div className="text-xl font-semibold">
                            Add {type} account
                        </div>
                        <div
                            className="bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200 p-3 ml-2"
                            onClick={close}
                        >
                            <img src={images.Close} alt="Close" />
                        </div>
                    </div>

                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                            <div className="grid grid-cols-2 gap-4">
                                {currencies.map(option => (
                                    <div
                                        key={option}
                                        className={`flex items-center border rounded-md p-4 cursor-pointer ${option === v.currency ? 'bg-gray-50 border-2 border-primary' : 'hover:bg-gray-50'}`}
                                        onClick={() => {
                                            setValues(sv => ({ ...sv, code: option !== types.Currencies.NGN ? 'null' : '', currency: option }));
                                        }}
                                    >
                                        <img
                                            src={datatables.findCurrencyImage(option)}
                                            alt={option}
                                            style={{ height: 30 }}
                                            className="p-1 rounded-full border"
                                        />&nbsp;&nbsp;
                                        {types.CurrenciesLabels[option]} ({option})
                                    </div>
                                ))}

                                {accounts.length > 0 && (
                                    <>
                                        <div className="col-span-2 border-t my-4" />
                                        <div className="col-span-2 mb-2s font-semibold">Choose a bank</div>
                                        {accounts.map(option => (
                                            <div
                                                key={option}
                                                className={`flex items-center border rounded-md p-4 cursor-pointer ${option === v.code ? 'bg-gray-50 border-2 border-primary' : 'hover:bg-gray-50'}`}
                                                onClick={() => {
                                                    setValues(sv => ({ ...sv, code: option }));
                                                }}
                                            >
                                                {helpers.ucWords(option.split('.')[1])}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-span-12 mt-12">
                            <Button type="primary" size="large" htmlType="submit" block loading={isLoading} disabled={!!!(v.currency && v.code)}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
};

export default FormVirtualAccount;