import { Dropdown, Tag } from 'antd';
import { Status } from 'components';
import { axius, helpers, images, types } from 'helpers';

export const findNetworkAccount = (network: string) => {
    switch (network) {
        case 'MTN':
            return images.IconMtn;
        case 'VOD':
            return images.IconVodafone;
        case 'VISA':
            return images.Visa;
        case 'MASTERCARD':
            return images.MasterCard;
        case 'BEP20':
        case 'ERC20':
        case 'TRC20':
            return images.USDT;
    }
    return images.IconBank;
}

export const findCurrencyImage = (currency: types.Currencies) => {
    switch (currency) {
        default:
            return images.IconBank;
        case types.Currencies.GHS:
            return images.Ghana;
        case types.Currencies.NGN:
            return images.Nigeria;
        case types.Currencies.USDT:
            return images.USDT;
        case types.Currencies.UGX:
            return images.Uganda;
        case types.Currencies.KES:
            return images.Kenya;
        case types.Currencies.XOF:
            return images.IvoryCoast;
        case types.Currencies.XAF:
            return images.Cameroon;
        case types.Currencies.TZS:
            return images.Tanzania;
        case types.Currencies.USD:
            return images.UnitedStates;
        case types.Currencies.ZMW:
            return images.Zambia;
        case types.Currencies.USDC:
            return images.USDC;
    }
}

export const findNetworkOrBank = (row: types.Transaction | types.SettlementAccount) => {
    const config = JSON.parse(helpers.getStorageJson('utils').config);
    return (config.banks[row.currency] || []).find((bank: any) => bank.code === row.network)?.name || helpers.ucWords(row.network);
}

export const Transactions = {
    heading: ['Reference', 'Amount', 'Fee', 'Customer number', 'Customer name', 'Network', 'Status', 'Date', ''],
    data: (data: types.Transaction[]) => data.map(row => {
        return {
            id: row.id,
            reference: row.id,
            amount: (
                <span className={`font-medium ${row.type === types.TrxTypes.DEBIT ? 'success' : 'danger'}`}>
                    {row.type === types.TrxTypes.DEBIT ? '+' : '-'} {helpers.format.currency(row.metadata?.rate_amount || row.amount, row.metadata?.currency_destination || row.currency)}
                </span>
            ),
            fee: helpers.format.currency(row.fee, row.currency),
            number: helpers.format.phoneNumber(row.customer?.accountNumber),
            customer: row.customer?.accountName,
            network: (
                <span className="flex items-center">
                    <img
                        src={findNetworkAccount(row.network)}
                        alt={row.network}
                        className="mr-2 border rounded-lg p-1"
                        style={{ height: 25 }}
                    />
                    {findNetworkOrBank(row)}
                </span>
            ),
            status: <Status status={row.status} label={types.TrxStatusLabels[row.status]} />,
            date: helpers.format.date(row.created_at),
            actions: {
                clickable: false,
                value: (
                    <>
                        <Dropdown
                            trigger={['click']} placement="bottom"
                            menu={{
                                items: [
                                    {
                                        key: 'hook',
                                        label: (
                                            <div onClick={() => axius.post(`transactions/${row.id}/resend-webhook`)}>
                                                Resend webhook
                                            </div>
                                        ),
                                    },
                                ],
                            }}
                        >
                            {/* <Button type="text" shape="circle" icon={<i className="fa fa-ellipsis-hs text-xl">...</i>} /> */}
                            <i className="fa fa-ellipsis-hs text-3xl">...</i>
                        </Dropdown>
                    </>
                ),
            }
        }
    }),
    export: (data: types.Transaction[], title: string) => (data || []).map((a: types.Transaction) => {
        return {
            'Reference': a.id,
            'Amount': a.amount,
            'Fee': a.fee - (a.metadata?.f || 0),
            'SMS Fee': a.metadata?.smsFee || 0,
            'Fee Bearer': a.bear_fee,
            'Amount After Fee': (a.bear_fee === 'business' ? a.amount - a.fee : a.amount),
            'Customer Name': a.customer.accountName || '',
            'Customer Number': a.customer.accountNumber,
            'Sender Name': a.metadata?.senderName || a.metadata?.sender_name || '',
            'Channel': types.TrxChannelsLabels[a.channel as keyof typeof types.TrxChannelsLabels],
            'Network': findNetworkOrBank(a),
            'Status': types.TrxStatusLabels[a.status as keyof typeof types.TrxStatusLabels],
            'Provider Message': a.provider_message || '',
            'Reason': a.reason,
            'Type': title,
            'Currency': a.currency,
            'Business Reference': (a as any).reference_business || '',
            'Date': a.created_at,
            'Opening Balance': a.opening_balance,
            'Closing Balance': a.closing_balance,
        };
    }),
};

export const Business = {
    heading: ['Name', 'Amount', 'Customer name', 'Channel', 'Status', 'Date'],
    data: (data: types.Business[]) => data.map(row => {
        const teams = (row.teams || {}).length > 0 ? row.teams : [{ user: {} as types.User }];
        return {
            id: row.id,
            businessName: row.name,
            email: teams[0].user.email,
            phone_number: teams[0].user.phone_number,
            userName: `${teams[0].user.first_name} ${teams[0].user.last_name}`,
            status: <Status status={row.status} label={types.TeamStatusLabels[row.status]} />,
            date: helpers.format.date(row.created_at),
        };
    })
};

export const Team = {
    heading: ['Email address', 'Role', 'Status', 'Date'],
    data: (data: (types.User | types.TeamInvite)[], me: types.User) => data.map(row => {
        return {
            id: row.id,
            email: <div>{row.email} {row.id === me.id ? <Tag>You</Tag> : ''}</div>,
            role: (row as any).admin_role?.name || 'Owner',
            status: <Status status={row.status || types.TeamStatus.ACCEPTED} label={types.TeamStatusLabels[row.status || types.TeamStatus.ACCEPTED]} />,
            date: helpers.format.date(row.created_at),
        };
    })
};

export const AdminRole = {
    heading: ['Name', 'Permission count', 'Date'],
    data: (data: types.AdminRole[]) => data.map(row => {
        return {
            id: row.id,
            name: row.name,
            count: helpers.format.number(row.permissions.length, 0),
            date: helpers.format.date(row.created_at),
        };
    })
};

export const PayLinks = {
    heading: ['Link Title', 'Currency', 'Amount', 'Type', 'Created', 'Link'],
    data: (data: types.PayLink[]) => data.map(row => {
        return {
            id: row.id,
            name: row.name,
            currency: row.currency,
            amount: +row.amount ? helpers.format.currency(row.amount, row.currency) : '-',
            type: types.PayLinksTypesLabels[row.type],
            date: helpers.format.date(row.created_at),
            link: {
                clickable: false,
                value: (
                    <div
                        className="primary p-2 bg-gray-200s text-right font-medium inline-block"
                        onClick={() => {
                            helpers.openNewTab(row.pay_link);
                        }}
                    >
                        Preview
                    </div>
                )
            },
        }
    }),
};