import React from 'react';
import { datatables, helpers, images, types } from 'helpers';
import { useTypedSelector } from 'Store/_Store';
import Converter from './Converter';

interface Props {
    onChange?: (e: types.Currencies) => void;
}
const Balances = (props: Props) => {

    const { _auth: { business }, _utils } = useTypedSelector((state) => state);
    const env = _utils.env === 'test' ? '_test' : '';

    const [convert, setConvert] = React.useState(false);
    const [currency, setCurrency] = React.useState<types.Currencies>();

    const prefs = business.preferences?.permissions || [];
    const currencies = (business.wallets?.filter(w => +w[`balance${env}`] > 0) || []) as types.Wallet[];

    React.useEffect(() => {
        currency && props.onChange && props.onChange(currency);
        // eslint-disable-next-line
    }, [currency]);

    return (
        <React.Fragment>
            {(business?.wallets || []).length > 1 && helpers.hasPermission('BALANCES') && (
                <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-4 mb-4">
                    {(business.wallets || []).filter(w => prefs.includes(w.currency) && w.currency !== types.Currencies.USDT).map(wallet => {
                        const balance_hold = wallet[`balance_hold${env}`] || 0;
                        return (
                            <div
                                key={wallet.id}
                                className={`bg-white rounded-xl p-6 border border-white ${props.onChange ? 'cursor-pointer pop hover:border-green-700' : ''} ${currency === wallet.currency ? 'border-green-700' : ''} transi`}
                                onClick={() => props.onChange && setCurrency(wallet.currency)}
                            >
                                <div className="text-gray-500 mb-2 flex items-center text-sm gap-2">
                                    <img src={datatables.findCurrencyImage(wallet.currency)} alt={wallet.currency} className="rounded h-[15px]" />
                                    {types.CurrenciesLabels[wallet.currency]}
                                </div>
                                <div className="text-xl font-bold">{helpers.format.currency(wallet[`balance${env}`], wallet.currency)}</div>
                                <div className="text-sm font-semibold text-red-500 mt-3">
                                    {balance_hold && balance_hold > 0 ? helpers.format.currency(balance_hold, wallet.currency) : '0.00'}
                                </div>
                            </div>
                        )
                    })}
                    {currencies.length > 0 && (
                        <div
                            className={`bg-white rounded-xl p-6 cursor-pointer border border-white pop hover:border-green-700 items-center justify-center flex flex-col transi`}
                            onClick={() => setConvert(true)}
                        >
                            <img src={images.Converter} alt="Converter" className="mb-23 rounded h-[28px]" />
                            Currency converter
                        </div>
                    )}
                </div>
            )}

            <Converter
                open={convert}
                onClose={() => setConvert(false)}
            />
        </React.Fragment>
    );
};

export default Balances;